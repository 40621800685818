<template>
	<header>
		<b-navbar toggleable="md" type="dark" variant="dark" class="px-4">
			<b-navbar-brand :to="{ name: 'Home' }">Taxonometry</b-navbar-brand>
			<!-- <b-navbar-brand>Taxonometry</b-navbar-brand> -->

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav fill>
					<b-nav-item-dropdown text="Competitors" right>
						<!-- <b-dropdown-item
							:to="{ name: 'TaxonomyKeyword' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Mass SERPs Analyzer"
							>Keyword</b-dropdown-item
						> -->
						<!-- <b-dropdown-item
							:to="{ name: 'TaxonomyUrls' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Multiple URLs"
							>URLs</b-dropdown-item
						> -->
						<!-- <b-dropdown-item
							:to="{ name: 'TaxonomyCompetitors' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Competitors"
							>Competitors</b-dropdown-item
						>
						> -->
						<!-- <b-dropdown-item
							:to="{ name: 'TaxonomyCompetitorsAdvanced' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Competitors Advanced"
							>Competitors Advanced</b-dropdown-item
						> -->
						<b-dropdown-item
							:to="{ name: 'Competitors' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Competitors"
							>Analyze</b-dropdown-item
						>
						<b-dropdown-item
							:to="{ name: 'CompetitorsResults' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Resuls"
							>Stored Results</b-dropdown-item
						>
						<!-- <b-dropdown-item
							:to="{ name: 'TaxonomyUrl' }"
							v-b-tooltip.hover.left="{ variant: 'info' }"
							title="Single URL Analyzer"
							>URL</b-dropdown-item
						> -->
					</b-nav-item-dropdown>

					<!-- <b-nav-item :to="{ name: 'TaxonomyKeywords' }">Keywords✔️</b-nav-item> -->

					<!-- <b-nav-item-dropdown text="DB" right>
						<b-dropdown-item :to="{ name: 'DB' }">Manage</b-dropdown-item>
					</b-nav-item-dropdown> -->

					<b-nav-item-dropdown text="URLs" right>
						<b-dropdown-item :to="{ name: 'TaxonomyUrls' }"
							>Multiple URLs Analyzer</b-dropdown-item
						>
						<!-- <b-dropdown-item :to="{ name: 'Crawler' }">Crawler</b-dropdown-item> -->
					</b-nav-item-dropdown>
					<b-nav-item-dropdown text="Tools" right>
						<b-dropdown-item :to="{ name: 'Headers' }"
							>Headers extractor
						</b-dropdown-item>
						<!-- <b-dropdown-item :to="{ name: 'TF-IDF' }">TF-IDF</b-dropdown-item> -->
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>

			<b-button size="sm" squared @click="logout">Logout</b-button>
		</b-navbar>
	</header>
</template>

<script>
export default {
	name: "Header",
	methods: {
		async logout() {
			await this.$store.dispatch("logout");
			this.$router.push({ name: "Login" });
		},
	},
};
</script>

<style>
</style>