import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import { AuthService } from "../services/auth/auth.service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {}
  },

  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  },

  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
  },

  actions: {
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        return AuthService.login(user)
        .then(resp => {
          const token = resp.data.token;
          const username = resp.data.username;
          console.log(username, token);
          localStorage.setItem('token', token);
          Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          commit('auth_success', token, { username });
          resolve(resp);
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete Axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },

  modules: {}
})
