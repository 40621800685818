<template>
  <b-row class="home d-flex flex-row flex-wrap">
    <b-col md="3">
      <NavCard
        title="Taxonomy"
        subtitle="Ultimate extractor"
        :to="{ name: 'TaxonomyKeyword' }" />
    </b-col>
    <b-col md="3">
      <NavCard
        title="Taxonomy keywords"
        subtitle="Processed keywords"
        :to="{ name: 'TaxonomyKeywords' }" />
    </b-col>
    <b-col md="3">
      <NavCard
        title="DB"
        subtitle="Database manager"
        :to="{ name: 'DB' }" />
    </b-col>
  </b-row>
</template>

<script>
import NavCard from "../components/NavCard.vue";

export default {
  name: 'Home',
  components: {
    NavCard
  }
}
</script>
