<template>
  <div id="app">
    <app-header v-if="$store.getters.isLoggedIn" />
    <b-container fluid>
      <router-view/>
    </b-container>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue"
export default {
  components: {
    AppHeader
  }
}
</script>
