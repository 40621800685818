import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/db',
    name: 'DB',
    component: () => import(/* webpackChunkName: "db" */ '../views/DB.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/taxonomy/keyword/:keyword?/:language_code?/:location_code?/:device?/:serp_top_n?/:forceUpdate?',
    name: 'TaxonomyKeyword',
    component: () => import(/* webpackChunkName: "taxonomy-keyword" */ '../views/TaxonomyKeyword.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/taxonomy/url/:url?/:language_code?/:location_code?/:device?/:serp_top_n?/:forceUpdate?',
    name: 'TaxonomyUrl',
    component: () => import(/* webpackChunkName: "taxonomy-url" */ '../views/TaxonomyUrl.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    // path: '/taxonomy/urls/:urlsString?/:language_code?/:location_code?/:device?/:serp_top_n?/:forceUpdate?',
    path: '/taxonomy/urls',
    name: 'TaxonomyUrls',
    component: () => import(/* webpackChunkName: "taxonomy-urls" */ '../views/TaxonomyUrls.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    // path: '/taxonomy/urls/:urlsString?/:language_code?/:location_code?/:device?/:serp_top_n?/:forceUpdate?',
    path: '/competitors/analyse',
    name: 'Competitors',
    component: () => import(/* webpackChunkName: "competitors" */ '../views/Competitors.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    // path: '/taxonomy/urls/:urlsString?/:language_code?/:location_code?/:device?/:serp_top_n?/:forceUpdate?',
    path: '/competitors/results',
    name: 'CompetitorsResults',
    component: () => import(/* webpackChunkName: "competitors" */ '../views/CompetitorsResults.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/taxonomy/competitors_missing',
  //   name: 'TaxonomyCompetitorsMissing',
  //   component: () => import(/* webpackChunkName: "taxonomy-competitors_missing" */ '../views/TaxonomyCompetitorsMissing.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    path: '/taxonomy/competitors_advanced',
    name: 'TaxonomyCompetitorsAdvanced',
    component: () => import(/* webpackChunkName: "taxonomy-competitors_advanced" */ '../views/TaxonomyCompetitorsAdvanced.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/taxonomy/keywords',
    name: 'TaxonomyKeywords',
    component: () => import(/* webpackChunkName: "taxonomy-keywords" */ '../views/TaxonomyKeywords.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/multiple-pages/urls',
    name: 'MultiplePagesUrls',
    component: () => import(/* webpackChunkName: "multiple-pages" */ '../views/MultiplePagesUrls.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tools/headers',
    name: 'Headers',
    component: () => import(/* webpackChunkName: "tools-headers" */ '../views/Headers.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/crawler',
    name: 'Crawler',
    component: () => import(/* webpackChunkName: "crawler" */ '../views/Crawler.vue'),
    meta: {
      requiresAuth: true,
    }
  },

]

const router = new VueRouter({
  routes
});

router.afterEach(() => {
  Vue.nextTick(() => {
    document.title = "MADGE | Taxonomy tool";
  });
});

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && to.name === "Login") {
    next({ name: "Dash" })
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
    } else {
      next({ name: "Login" })
    }
  } else {
    next()
  }
})

export default router
