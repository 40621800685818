import { BaseService } from "../base.service";
import { ResponseWrapper } from "../util";
export class AuthService extends BaseService {
  static get entity() {
    return "auth"
  }

  static apiClient(options) {
    const baseURL = process.env.VUE_APP_VUE_APP_API_URL;
    return this.initApiClient({ baseURL, ...options });
  }

  static async login({ username, password }) {
    const response = await this.apiClient().post(`/${this.entity}`, {
      username,
      password
    });
    return new ResponseWrapper(response, response.data);
  }
}