<template>
  <div>
    <router-link :to="to" class="text-decoration-none">
      <b-card class="bg-info d-flex align-items-center pt-2 pb-4 m-4 mx-2" no-body>
        <b-card-title class="p-3 px-5 m-0 text-white">{{ title }}</b-card-title>
        <b-card-sub-title v-if="subtitle" class="text-white">{{subtitle}}</b-card-sub-title>
      </b-card>
    </router-link>
  </div>
</template>

<script>  
export default {
  name: "NavigationCard",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    to: {
      type: Object,
      required: true,
    }
  }
}
</script>
