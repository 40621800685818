import qs from "qs";
import { ResponseWrapper, ErrorWrapper } from "./util";
import { initApiClient } from "./init-api-client";

export class BaseService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static get entity() {
    throw new Error("entity getter not defined");
  }

  static initApiClient(options) {
    return initApiClient(options);
  }

  static responseWrapper(...rest) {
    return new ResponseWrapper(...rest);
  }

  static errorWrapper(...rest) {
    return new ErrorWrapper(...rest);
  }

  static querystring(obj) {
    return qs.stringify(obj, {
      encode: false,
    });
  }

  static async getList(params = {}) {
    try {
      const response = await this.apiClient().get(`${this.entity}`, { params });
      const data = new ResponseWrapper(response, response.data);
      return data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getById(id) {
    try {
      const response = await this.apiClient().get(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async create(data = {}) {
    try {
      const response = await this.apiClient({ auth: true }).post(`${this.entity}`, data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async update(data = {}) {
    try {
      const response = await this.apiClient({ auth: true }).patch(`${this.entity}`, data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async remove(id) {
    try {
      const response = await this.apiClient({ auth: true }).delete(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
