import Axios from "axios";
import store from "../store";

Axios.interceptors.response.use(response => response, (err) => {
  return new Promise(function () {
    if (err.status === 401) {
      store.dispatch("logout")
    }
    throw err;
  })
});

const token = localStorage.getItem('token')
if (token) {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const initApiClient = ({ baseURL }) => {
  const axios = Axios.create({
    baseURL: baseURL || process.env.VUE_APP_API_URL,
  });
  return axios;
}
